/*Marine*/
/*Grapefruit*/
/*Watermelon*/
/*Grey*/
/*Lime*/
/*Forest green*/
/*Aqua*/
/*Lilac*/
/*Pale pink*/
/*Cherry*/
table[data-v-76d79164] {
  border-radius: 3px;
  table-layout: auto;
  width: 100%;
  border-collapse: collapse;
}
th[data-v-76d79164] {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  font-weight: bold;
  font-size: 1.4rem;
  text-align: left;
}
th[data-v-76d79164],
td[data-v-76d79164] {
  padding: 0.5em 1em;
  border: 1px solid white;
}
tr[data-v-76d79164] {
  border: 1px solid white;
}
tbody td[data-v-76d79164] {
  font-size: 1.2rem;
}
tr[data-v-76d79164]:nth-of-type(even) {
  background: #dadada;
}
.manager--content[data-v-76d79164] {
  padding: 1rem;
  display: flex;
}
.bg-grey[data-v-76d79164] {
  background: #f3f3f3;
}
.h-100[data-v-76d79164] {
  height: 100%;
}
.w-100[data-v-76d79164] {
  width: 100%;
}
.center[data-v-76d79164] {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}